<script setup lang="ts">
import type { Props } from './inputProps';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import VaContentLoader from '@/components/framework/va-content-loader.vue';
import { useTimeout } from '@/composables/useTimeout';

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  theme: 'dark',
});

const { startTimeout } = useTimeout(props.delay);

const emit = defineEmits([
  'update:modelValue',
  'keyup',
  'keydown',
  'focus',
  'blur',
]);

const onInput = (value: string) => {
  if (props.delay) {
    startTimeout(() => {
      emit('update:modelValue', value);
    });
  } else {
    emit('update:modelValue', value);
  }
};
</script>

<template>
  <div :class="['va-input', theme, { disabled: disabled }]">
    <label>
      <span v-if="props.label" class="va-input--label">{{ props.label }}</span>
      <div class="va-input--field">
        <VaContentLoader
          v-if="props.loading"
          :style="{ width: props.width, height: '2.5rem' }"
        />
        <input
          v-else
          :class="[
            'va-input--input',
            {
              'right-align': props.rightAlign,
              'has-errors': Array.isArray(props.errors)
                ? props.errors.length
                : props.errors,
              'padding-right': $slots['append-inner'] || props.clearable,
              rounded: props.rounded,
            },
          ]"
          data-testid="va-input"
          :style="{ width: props.width }"
          :type="props.type"
          :value="props.modelValue"
          :placeholder="props.placeholder"
          :min="props.min"
          :max="props.max"
          :disabled="props.disabled"
          :autocomplete="props.autocomplete"
          :maxlength="props.maxLength"
          @focus="emit('focus')"
          @blur="emit('blur')"
          @keyup="emit('keyup', $event)"
          @keydown="emit('keydown', $event)"
          @input="onInput(($event.target as HTMLInputElement).value)"
        />
        <SvgIcon
          v-if="props.clearable && props.modelValue"
          icon="close"
          class="append-inner clickable hover-effect-highlight"
          :class="{ 'extra-padding-right': props.rounded }"
          data-testid="va-input--clear"
          @click="emit('update:modelValue', '')"
        />
        <div
          v-else-if="$slots['append-inner']"
          class="append-inner"
          :class="{ 'extra-padding-right': props.rounded }"
        >
          <slot name="append-inner"></slot>
        </div>
        <slot name="append"></slot>
      </div>
    </label>
    <div
      v-if="Array.isArray(props.errors) && props.errors.length"
      class="error-messages"
    >
      <p
        v-for="(error, index) in props.errors"
        :key="index"
        data-testid="va-input--error"
        class="error-message"
      >
        <SvgIcon icon="Warning" />
        &nbsp;
        {{ error }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.va-input {
  position: relative;
  width: 100%;

  &.disabled {
    opacity: 0.5;
  }

  &--field {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .append-inner {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0.8rem;

      &.extra-padding-right {
        right: 1.1rem;
      }
    }
  }

  &--label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    color: $color-text-secondary;
    line-height: 1.25rem;
    letter-spacing: 0.035rem;
    text-align: left;
    cursor: pointer;
  }

  &--input {
    box-sizing: border-box;
    background-color: $color-input;
    border: 1px solid transparent;
    outline: none;
    color: $color-text;
    height: 2.5rem;
    width: 100%;
    padding: 0 0.875rem;

    &.has-errors {
      border: 1px solid $color-border-danger;
    }

    &.padding-right {
      padding-right: 1.4rem;
    }

    &.rounded {
      border-radius: 19.5px;
      padding-left: 1.25rem;

      &.padding-right {
        padding-right: 2.4rem;
      }
    }
  }

  &.light &--input {
    background-color: $color-input-inverted;
    color: $color-text-inverted;
  }

  .right-align {
    text-align: right;
    padding-right: 0;
  }

  .error-messages {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .error-message {
      color: $color-text-danger;
      font-size: 0.75rem;
      line-height: 1;
      display: flex;
      align-items: center;
      padding: 0;
    }
  }
}
</style>

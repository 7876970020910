import { ref } from 'vue';

export function useTimeout(initialMs: number = 300) {
  const ms = ref(initialMs);
  const timeout = ref<ReturnType<typeof setTimeout> | null>(null);

  function setMilliseconds(value: number) {
    ms.value = value;
  }

  function startTimeout(callback: () => void) {
    if (timeout.value) {
      clearTimeout(timeout.value);
    }

    timeout.value = setTimeout(() => {
      callback();
    }, ms.value);
  }

  return {
    ms,
    setMilliseconds,
    startTimeout,
  };
}
